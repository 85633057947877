header {
    /* background-color: black; */
    background-color: #182828;
    /* background-color: #2f4f4f; */
    /* height: 100vh; */
    padding-top: 3rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    position: relative;
    height: 100%;

}

.typewriter {
    /* Ensures the content is not revealed until the animation */
    overflow: hidden;
    /* The typwriter cursor */
    border-right: .65em solid orange;
    /* Keeps the content on a single line */
    white-space: nowrap;
    /* Gives that scrolling effect as the typing happens */
    margin: auto;
    font-size: 1rem;
    /* for fixing width problems  */
    max-width: fit-content;
    padding: auto;
    /* Adjust as needed */
    letter-spacing: .15em;
    animation:
        /* ideal for desktop */
        /* typing 8s steps(120, end), */
        typing 8s steps(120, end),
        blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }

}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 5rem;
    justify-content: center;
}

.header__socialsCard {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-content: center;
}

/* my mod  */
.header__socials {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-content: center;
    align-items: baseline;
    /* position: absolute;
    bottom: 3rem;
    left: 0; */
}

.header__socials::before,
.header__socials::after {
    content: '';
    /* height: 5px; */
    width: 2rem;
    width: 5px;
    /* height: 2rem; */
    background: var(--color-primary);

}

.content__body {
    margin-top: 2.5rem;
    display: flex;
    gap: 5rem;
    justify-content: center;

}

.bg__circle {
    /* background: var(--color-primary); */
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    align-content: center;
    vertical-align: middle;


}

#circle-avatar {
    vertical-align: middle;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
}

.contact__me {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transition: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}